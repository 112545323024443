import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  showModal(event) {
    event.preventDefault();
    const url = event.params.url;
    const form = document.querySelector(`${event.params.target} form`);
    form.action = url;
  }
}
