import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="copy-field-value"
export default class extends Controller {
  static targets = ["default", "copied"];
  static values = {
    selector: String
  };

  connect() {
    this.element.addEventListener("click", this.onElementClick);
    this.defaultTarget.style.display="inline";
    this.copiedTarget.style.display="none";
  }

  onElementClick = (e) => {
    const target = document.querySelector(this.selectorValue);

    copyToClipboard(target.value || "");

    this.defaultTarget.style.display="none";
    this.copiedTarget.style.display="inline";
  };
}

// https://stackoverflow.com/a/33928558
// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
// Internet Explorer: The clipboard feature may be disabled by
// an administrator. By default a prompt is shown the first
// time the clipboard is used (per session).
function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData("Text", text);
  }
  else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
          return document.execCommand("copy");  // Security exception may be thrown by some browsers.
      }
      catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return prompt("Copy to clipboard: Ctrl+C, Enter", text);
      }
      finally {
          document.body.removeChild(textarea);
      }
  }
}
