import "@hotwired/turbo-rails"
import "trix";
import "@rails/actiontext";
import * as bootstrap from "bootstrap";
import TomSelect from "tom-select";
import "./controllers";
import "./channels"
import "./config"

document.addEventListener('turbo:load', function() {
  document.querySelectorAll('.form-select-tom').forEach((select) => {
    new TomSelect(select, { closeAfterSelect: true });
  });

  document.querySelectorAll('.form-select-tom-emails').forEach((select) => {
    new TomSelect(select, { create: true, delimiter: ',' });
  });
});

document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(tooltip => {
  new bootstrap.Tooltip(tooltip)
})
