import consumer from "./consumer";

consumer.subscriptions.create("InvoicesChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const html = data.html;
    const toast = document.createElement("div");
    toast.innerHTML = html ? html : this.toastHtml(data);
    document.getElementById("toast-messages").appendChild(toast);
  },

  // fallback option if HTML from broadcaster won't arrive
  toastHtml({duration, invoice_id}) {
    const url = `/invoices/${invoice_id}`;
     return `<div class="toast show">
        <div class="toast-body">
          <p>Your invoice finished processing in ${duration}s</p>
          <p>
            Click <a href="${url}">this link</a> to view/edit invoice
            or just <a href="#" onclick="window.location.reload()">refresh</a> this page
          </p>
        </div>
      </div>`;
  }
});
