import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    changed: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
  };

  connect() {
    this.element.addEventListener("input", this.onInput);
  }

  disconnect() {
    document.removeEventListener("turbo:before-visit", this.onOnBeforeUnload);
    document.removeEventListener("beforeunload", this.onOnBeforeUnload);
    this.element.removeEventListener("submit", this.onSubmit);
    this.savingValue = false;
  }

  onInput = (e) => {
    this.savingValue = false;

    const prev = this.changedValue;
    this.changedValue = true;

    if (prev !== this.changedValue) {
      this.element.addEventListener("submit", this.onSubmit);
      document.addEventListener("turbo:before-visit", this.onOnBeforeUnload);
      document.addEventListener("beforeunload", this.onOnBeforeUnload);
    }
  };

  onOnBeforeUnload = (e) => {
    if (this.savingValue) {
      e.preventDefault();
      return true;
    }

    if (!window.confirm("Leave without saving changes?")) {
      e.preventDefault();
      return false;
    }

    this.savingValue = false;
    return true;
  };

  onSubmit = (e) => {
    this.savingValue = true;
    /*
    this.savingValue = window.confirm("Save the changes?");
    if (!this.savingValue) {
      e.preventDefault();
      return false;
    }
    */

    return true;
  };
}
