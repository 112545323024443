import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  setFilter(event) {
    event.preventDefault();
    let value = event.params.value || event.target.value;
    let params = (new URL(window.location)).searchParams;
    let search = event.params.search;
    (value === undefined) ? params.delete(search) : params.set(search, value);
    window.location.search = params.toString();
  }

  resetFilters(event) {
    event.preventDefault();
    let value = event.params.value;
    let paramNames = (value || "").split(",").filter((v) => v.length > 0);
    let params = (new URL(window.location)).searchParams;
    if (paramNames.length > 0) {
      paramNames.forEach(prm => { params.delete(prm.trim()); });
      window.location.search = params.toString();
    } else {
      window.location.search = "";
    }
  }
}
