import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = [ "form" ];

  connect() {
    this.formTarget.addEventListener("submit", this.onFormSubmit);
    this.element.addEventListener("show.bs.offcanvas", this.onShow);

    document.addEventListener("turbo:before-stream-render", this.scrollToBottom);

    const hash = window.location.hash;
    if (hash && hash === "#showConversation") {
      const bsOffcanvas = new bootstrap.Offcanvas(this.element);
      bsOffcanvas.show();
    }
  }

  disconnect() {
    this.formTarget.removeEventListener("submit");
    this.element.removeEventListener("show.bs.offcanvas");
    document.removeEventListener("turbo:before-stream-render", this.scrollToBottom);
  }

  scrollToBottom = () => {
    setTimeout(()=> {
      this.element.querySelector(".offcanvas-body")
          .scrollTo({
            top: 10000000,
            left: 0,
            behavior: "smooth",
          });
      const id = this.element.dataset.statusId;
      const csrfToken = document.querySelector("[name='csrf-token']").content;
      if (id) {
        fetch("/conversation/statuses/" + id + "/mark_as_read", {
          method: "PUT",
          headers: {
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json"
          }
        });
      }
    }, 500);
  };

  onShow = (e) => {
    this.scrollToBottom();
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    fetch(form.action, {
      method: "POST",
      body: data
    });
    form.reset(); // reset the form
  };
}
